export class WebChatWidget {
  private readonly buttonContainer: any
  private readonly chatContainer: any
  private isOpen: boolean
  private readonly isChatLoaded: boolean
  private readonly isFullScreen: boolean
  private readonly appKey: string | null
  private readonly baseUrl: string | null
  private readonly buttonConfig: { color?: string, icon?: string, size?: string, iconSize?: string, closeColor?: string } | null
  private readonly customStyle: string | null
  private readonly previewMode: boolean

  constructor (
    baseUrl: string | null,
    appKey: string | null,
    buttonConfig: { color?: string, icon?: string, size?: string, iconSize?: string, closeColor?: string } | null,
    customStyle: string | null,
    previewMode: boolean
  ) {
    this.appKey = appKey
    this.baseUrl = baseUrl
    this.buttonConfig = buttonConfig
    this.customStyle = customStyle
    this.previewMode = previewMode
    this.buttonContainer = document.createElement('div')
    this.chatContainer = document.createElement('div')
    this.isOpen = false
    this.isChatLoaded = false
    this.isFullScreen = false
    this._onInit()
  }

  private _onInit (): void {
    this.buttonContainer.id = 'webChatChannel'
    this.buttonContainer.style.display = 'flex'
    this.buttonContainer.style.justifyContent = 'center'
    this.buttonContainer.style.alignItems = 'center'
    this.buttonContainer.style.backgroundColor = this.buttonConfig?.color ?? '#fff'
    this.buttonContainer.style.backgroundImage = `url(${this.buttonConfig?.icon ?? 'https://cdn-icons-png.flaticon.com/128/684/684849.png'})`
    this.buttonContainer.style.backgroundRepeat = 'no-repeat'
    this.buttonContainer.style.backgroundSize = this.buttonConfig?.iconSize ?? '50%'
    this.buttonContainer.style.backgroundPosition = 'center center'
    this.buttonContainer.style.borderRadius = '50%'
    this.buttonContainer.style.width = this.buttonConfig?.size ?? '64px'
    this.buttonContainer.style.height = this.buttonConfig?.size ?? '64px'
    this.buttonContainer.style.bottom = '24px'
    this.buttonContainer.style.right = '24px'
    this.buttonContainer.style.position = 'fixed'
    this.buttonContainer.style.zIndex = '9999'
    this.buttonContainer.style.cursor = 'pointer'
    this.buttonContainer.addEventListener('click', (event: any, self = this) => {
      self.toggleChat()
    })

    document.body.appendChild(this.buttonContainer)
  }

  private toggleChat (): void {
    this.isOpen = !this.isOpen
    if (this.isOpen) {
      this.buttonContainer.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 256 256' width='50px' height='50px' fill-rule='nonzero'%3E%3Cg fill='%23${this.buttonConfig?.closeColor?.replace('#', '') ?? '000000'}' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cg transform='scale(5.12 5.12)'%3E%3Cpath d='M9.15625 6.3125l-2.84375 2.84375l15.84375 15.84375l-15.9375 15.96875l2.8125 2.8125l15.96875 -15.9375l15.9375 15.9375l2.84375 -2.84375l-15.9375 -15.9375l15.84375 -15.84375l-2.84375 -2.84375l-15.84375 15.84375z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
      this.buttonContainer.style.backgroundSize = '50%'
      this.chatContainer.id = 'webChatContainer'
      this.chatContainer.style.backgroundColor = this.buttonConfig?.color ?? '#fff'
      this.chatContainer.style.backgroundImage = 'url(https://usagif.com/wp-content/uploads/loading-25.gif.webp)'
      this.chatContainer.style.backgroundRepeat = 'no-repeat'
      this.chatContainer.style.backgroundSize = '48px'
      this.chatContainer.style.backgroundPosition = 'center center'
      this.chatContainer.style.display = 'flex'
      this.chatContainer.style.bottom = `calc(34px + ${this.buttonConfig?.size ?? '64px'})`
      this.chatContainer.style.right = '24px'
      this.chatContainer.style.width = '400px'
      this.chatContainer.style.height = '550px'
      this.chatContainer.style.position = 'fixed'
      this.chatContainer.style.borderRadius = '8px'
      this.chatContainer.style.zIndex = '9999'
      this.chatContainer.innerHTML = `<iframe height="550px" width="400px" frameborder="0" src="https://${this.baseUrl ?? ''}/chat/${this.appKey ?? ''}${this.previewMode ? '?preview=true' : ''}" style="-webkit-border-radius: 8px;border-radius: 8px;"></iframe>`
      document.body.appendChild(this.chatContainer)
    } else {
      this.buttonContainer.style.backgroundImage = `url(${this.buttonConfig?.icon ?? 'https://cdn-icons-png.flaticon.com/128/684/684849.png'})`
      this.buttonContainer.style.backgroundSize = this.buttonConfig?.iconSize ?? '50%'
      document.getElementById('webChatContainer')?.remove()
    }
  }

  destroy (): void {
    document.getElementById('webChatChannel')?.remove()
    document.getElementById('webChatContainer')?.remove()
  }
}
